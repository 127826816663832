import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DefaultLocale } from '~/settings/localeSetting'

Vue.use(VueI18n);

const lang = (store) => {
  const DefaultLang = (store && store.state.editor.EditMode)?DefaultLocale.EditMode : DefaultLocale.PreviewMode;
  return new VueI18n({
    locale: store ? store.state.locale.locale : DefaultLang.locale,
    fallbackLocale: DefaultLang.fallbackLocale,
    messages: {
      'cn': require('~/locales/cn.json'),
      'en': require('~/locales/en.json')
    }
  })
}

export default ({app, store}) => {
  app.i18n = lang(store)
  Vue._my_i18n = app.i18n;
}

export const i18n = lang()
