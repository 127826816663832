import { APIKEY, MERCHANT_ID, PROJECT_ID } from '~/config/xsolla'

const Api =  {
  token: `/xsolla/merchant/v2/merchants/${MERCHANT_ID}/token`,
  serviceToken: `/store_xsolla/api/v2/project/${PROJECT_ID}/admin/payment`,
  payMethod: `/xsolla/merchant/v2/projects/${PROJECT_ID}/payment_systems/search.json`
}



export default (request) => {

  return {
    getToken(data) {
      return request.post(Api.token, data, { headers: { "content-type": "application/json", 'Authorization': `Basic ${btoa(APIKEY)}` } })
    },
    getServiceToken(data) {
      return request.post(Api.serviceToken, data, { headers: { "content-type": "application/json", 'Authorization': `Basic ${btoa(APIKEY)}` } })
    },
    getPayMethods(params) {
      return request.get(Api.payMethod, { params, headers: { "content-type": "application/json", 'Authorization': `Basic ${btoa(APIKEY)}` } })
    }
  }
}
