const Api =  {
  edit: '/api/v2/merchant/site/builder/desc/edit', // /merchant/site_builder/desc/edit
  editList: '/api/v2/merchant/site/builder/language/edit'
}

export default (request) => {
  return {
    // 编辑接口
    edit: (data) => request.post(Api.edit, data),
    editList: (data) => request.post(Api.editList, data)
  }
}
