const Api =  {
    StoreCarts: '/api_customer/store/carts',
    PushCart: '/api_customer/store/cart/fill',
    UpdateCart: '/api_customer/store/cart/fill',
    RemoveCart: '/api_customer/store/cart/remove'
  }
  export default (request) => {
    return {
      //  购物车-查询
      getStoreCarts: (params) => request.get(Api.StoreCarts, {params}),
      
      /**
       * 购物车-添加商品
       * @param {*} params item_id 商品id
       * @param {*} params num     商品数量
       * @param {*} params project_id 项目id
       * @param {*} params user_id 用户角色id
       */
      pushCart: (params, options) => request.post(Api.PushCart, params, {...options}),
      //  购物车-更新商品
      updateCart: (params) => request.post(Api.UpdateCart, params),
      //  购物车-移除商品
      removeCart: (params) => request.post(Api.RemoveCart, params),
    }
  }