const Api = {
  PAYMENTMETODS: '/api_customer/pay/payment_methods',
  CREATEORDER: '/api/v2/store/order/create',
  ORDERDETAIL: '/api_customer/store/order',
  PAYMENT: '/api_customer/store/payment',
  PAYMENTFORPAY: '/api_customer/pay/payment',
  GETPAYCARDS: '/api_customer/pay/cards',
  GETPAYWALLETS: '/api_customer/pay/wallets',
  ORDERINFO: '/api/v2/store/order/info'
}

export default (request) => {
  return {
    // 支付方式列表
    getPayMethods: (params, headers = {}) =>
      request.get(Api.PAYMENTMETODS, { params, headers }),
    // 支付创建订单
    createOrder: (params, headers = {}) =>
      request.post(Api.CREATEORDER, params, { headers }),
    // 获取订单详情
    getOrderDetail: (params) =>
      request.get(`${Api.ORDERDETAIL}/${params.order_id}`),
    // 更新订单
    upDateOrder: (params) =>
      request.patch(
        `${Api.PAYMENT}/${params.payment_id}?client_secret=${params.client_secret}&user_id=${params.user_id}&project_id=${params.project_id}`,
        { receipt_email: params.receipt_email }
      ),
    // 获取支付详情
    getPayDetail: (params) =>
      request.get(`${Api.PAYMENT}/${params.payment_id}`, {
        params: { user_id: params.user_id, project_id: params.project_id },
      }),
    // 通用支付 （dlocal）
    payment: (params) => request.post(`${Api.PAYMENTFORPAY}`, params),
    // 获取已保存银行卡信息 （dlocal）
    getPayCards: (params) => request.get(Api.GETPAYCARDS, params),
    // 获取已保存钱包信息 （dlocal）
    getPayWallet: (params) => request.get(Api.GETPAYWALLETS, { params }),
    // 下单订单信息
    getOrderInfo: (params) => request.get(Api.ORDERINFO, { params })
  }
}
