import Vue from 'vue'
import PortalVue from 'portal-vue'
import globalComponentsInstall from '~/components/globalCompnentsInstall'
import Directive from '~/directive'
import utils from '~/utils/utils'
import '~/assets/style/index.less'
import Message from '~/components/message/index'
Vue.use(PortalVue)
Vue.prototype.$imessage = Message.install;
Vue.use(globalComponentsInstall)
Vue.use(Directive)
export default function (context, inject) {
  inject('utils', utils)
}
