import fetch from '~/api/fetch';
import {mergePromiseToSame} from '~/utils/api'

const Api =  {
  Upload: '/public/upload',
  Currencies: '/api/v2/base/countries/currency/pairs', // /api_customer/config/currencies
  Countrys: '/api/v2/base/countries/all', // /api_customer/config/countries2
  Provinces: '/api_customer/config/provinces',
  Cities: '/api_customer/config/cities',
  countryCurrency: '/api/v2/store/tool/country-currency' // /api_customer/config/country_currency
}

export default (request) => {
  return {
    //  上传
    upload: (params) => request.post(Api.Upload, params, {headers: {"Content-Type": "multipart/form-data"}}),
    //  货币列表-查询
    getCurrencies(params,options) {
      return mergePromiseToSame(Api.Currencies, params || {} ,options, request)
    },
    //  世界国家 列表
    getCountrys(params) {
      return fetch(request,{
        url: Api.Countrys,
        method: 'get',
        params
      })
    },
    //  省/州 列表
    getProvinces(params) {
      return fetch(request,{
        url: Api.Provinces,
        method: 'get',
        params
      })
    },
    //  城市 列表
    getCities(params) {
      return fetch(request,{
        url: Api.Cities,
        method: 'get',
        params
      })
    },
    //  获取当前ip 所在国家，货币
    getIpInfo: (params, options) => {
      return  mergePromiseToSame(Api.countryCurrency, params,options, request)
    }
  }
}
