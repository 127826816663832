import Vue from 'vue'
import SiteMenu from "~/site/core/menu";
import SitePage from '~/site/core/page'

if (!Vue.__siteMenu && !Vue.__sitePage) {
  Vue.__siteMenu = true
  Vue.__sitePage = true
  Vue.mixin({
    data() {
      return {
        SiteMenu,
        SitePage
      }
    }
  })
}
