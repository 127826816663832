import { PageEnum } from '~/enums/pageEnum'
const WhiteList = [
  PageEnum.BASE_HOME,
  PageEnum.BASE_401,
  PageEnum.FORBIDDEN_PAGE,
]
const BlackList = [
  PageEnum.SITE_PREVIEW,
  PageEnum.SITE_EDIT,
]
export const useRouterList = () => {
  //  whiteList 免登录列表
  return {
    whiteList: WhiteList,
    blackList: BlackList
  }
}
export const whiteList = WhiteList
export const blackList = BlackList
