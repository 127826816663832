const Api =  {
    Language: '/api/v2/base/language/all', // /merchant/config/languages
    Project: '/merchant/project',
    RechargeList: '/api/v2/merchant/recharge-price-level/list',
    Country: '/api/v2/base/countries/complete/all' // /merchant/config/countries
}
export default (request) => {
  return {
    getLanguage: (params) => request.get(Api.Language, params),
    getProject: (params) => request.get(`${Api.Project}/${params.project_id}`, params),
    getCountry: (params)=> request.get(`${Api.Country}`, params),
    getRechargeList: (params) => request.get(Api.RechargeList, { params })
  }
}
