const Api = {
  STORE_REPORTING: '/api/v2/store/point/reporting',
}

export default (request) => {
  return {
    // 数据上报接口
    eventTracking: (data) => request.post(Api.STORE_REPORTING, data),
  }
}
