const Api =  {
  token: '/api_merchant/pay_center/payment-ui-token',
  tokenSandbox: '/koppay_sandbox/api/v1/api_merchant/pay_center/payment-ui-token',
  payDetail: '/api_merchant/pay_center/payment/token',
  payDetailSandbox: '/koppay_sandbox/api/v1/api_merchant/pay_center/payment/token',
}



export default (request) => {
  return {
    getToken(data) {
      return request.post(Api.token, data, { headers: { "content-type": "application/json", 'Authorization': `Basic MTY6NzIxNDNlODk4ZWE0NDE2ZTk3MmVmNTQ3ZjJhMWYzZGU=` } })
    },
    getTokenSandbox(data) {
      return request.post(Api.tokenSandbox, data, { headers: { "content-type": "application/json", 'Authorization': `Basic MTY6NzIxNDNlODk4ZWE0NDE2ZTk3MmVmNTQ3ZjJhMWYzZGU=` } })
    },
    getOrderDetail(token) {
      return request.get(Api.payDetail + `?access_token=${token}`,  {})
    },
    getOrderDetailSandbox(token) {
      return request.get(Api.payDetailSandbox+ `?access_token=${token}`, {})
    }
  }
}
