import tinycolor from 'tinycolor2'
export const setAlpha = (color,alpha = 1)=>{
  let a = +alpha
  if(typeof  a === 'number'){
    if(a < 0){
      a = 0
    }else if( a > 1){
      a = 1
    }
  }else{
    a = 1
  }
  const c = tinycolor(color);
  if(c.isValid()){
    return c.setAlpha(a).toRgbString();
  }else{
    return  color
  }
}

export const getAlpha = (color)=>{
  const c = tinycolor(color);
  if(c.isValid()){
    return c.getAlpha();
  }else{
    return  color
  }
}

