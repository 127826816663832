// 转换老的price对象成新的对象
import {default_currency as DefaultCurrency}  from '~/config'
/*
* @params newPrice old => {'CN':{'cny': 6.7}, 'US':{'usd': 0.99}}
*
* return {'cny': 6.7, 'usd': 0.99}
* */
export const transformPriceToTemplate = (newPrice) => {
  if (!newPrice) {
    return  {
      [DefaultCurrency]: ''
    }
  }
  const values = Object.values(newPrice)
  if(values.length === 0){
    return  {
      [DefaultCurrency]: ''
    }
  }
  const type = typeof Object.values(newPrice)[0]
  if (type === 'string' || type === 'number') {
    return newPrice
  }
  const price = {}
  Object.values(newPrice).forEach(item => {
    Object.assign(price, item)
  })
  if(!price[DefaultCurrency]){
    price[DefaultCurrency] = ''
  }

  return price
}
// 转换老的price对象成新的对象
/*
* @params oldPrice old => {'cny': 6.7, 'usd': 0.99}
*
* return {'CN':{'cny': 6.7}, 'US':{'usd': 0.99}}
* */
export const transformToNewPrice = (priceInfo, dataSources) => {
  const prices = {};
  const defaultCurrency = priceInfo.currency
  const findDefault = dataSources.find((item) => item.id === defaultCurrency)
  let cCode = defaultCurrency.length > 2 ? defaultCurrency.slice(0, 2) : defaultCurrency
  if (findDefault) {
    cCode = findDefault.countryCode
  }
  prices[cCode] = {
    [defaultCurrency]: priceInfo.price
  }
  dataSources.forEach((e) => {
    prices[e.countryCode] = { // 增加国家代码
      [e.id]: e.newPrice || e.price
    }
  })
  return prices
}
export const getApiCurrenciesV2 = async (vueThis, prices, defaultPriceInfo) => {
  const [error, result] = await vueThis.$utils.to(vueThis.$api.common.getCurrenciesv2());
  const temp = []
  if (!error) {
    result.data.forEach((e) => {
      if (e.country_currency !== defaultPriceInfo.currency) {
        const obj = {
          id: e.country_currency,
          price: prices[e.country_currency] || 0,
          tax: e.tax, // 税费
          countryCode: e.country_code,
          name: e.country_name,
        };
        temp.push(obj)
      }
    })
  }
  return [error, temp]
}
export const promiseToEditPrices = (p) => {
  return new Promise((resolve, reject) => {
    p.then(res => {
      // if (Array.isArray(res.data)) {
      //   res.data.forEach(item => {
      //     item.prices = transformPriceToTemplate(item.prices)
      //   })
      // } else if (res.prices) {
      //   res.prices = transformPriceToTemplate(res.prices)
      // }
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
