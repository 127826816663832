import { LOCALE } from '~/settings/localeSetting'

export const state = () => ({
  locales: [LOCALE.ZH_CN, LOCALE.EN_US],
  locale: LOCALE.ZH_CN,
  langIdData: {}
})

export const mutations = {
  SET_LANG(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },

  SET_LANG_DATA(state, payload) {
    state.langIdData = payload
  },
}
