export const state = () => ({
  assetsList: []
})

export const mutations = {
  SET_ASSETS_LIST(state, data) {
    state.assetsList = data
  },
  ADD_ASSETS_ITEM(state, data) {
    state.assetsList.unshift(data)
  },
  UPDATE_ASSETS_ITEM(state, data) {
    state.assetsList.forEach((item, index) => {
      if (item.uid === data.uid) {
        state.assetsList.splice(index, 1, {...data})
      }
    })
  },
  DELETE_ASSETS_ITEM(state, index) {
    state.assetsList.splice(index, 1)
  }
}
export const actions = {
  //
}
