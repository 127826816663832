const rememberPromiseData = {}
const lastTimes = {}
export const mergePromiseToSame = (path, data, options = {}, request) => {
  if (!data || !path) {
    return Promise.reject(new Error('不能没有data和path'))
  }
  const key = path + '' + (typeof data === 'object' ? JSON.stringify(data) : data)
  const lastTime = lastTimes[key] || 0
  const timeDiffer = Date.now() - lastTime // 上次执行的时间
  const isInTime = timeDiffer <  2 * 1000 // 是否小于10s

  const promise = rememberPromiseData[key] || request.get(path, {
    params: data,
    ...options
  })
  if (!rememberPromiseData[key]) {
    lastTimes[key] = Date.now()
    rememberPromiseData[key] = promise
    return promise
  } else if (isInTime) {
    // eslint-disable-next-line no-return-assign
    return new Promise((resolve, reject) => {
      rememberPromiseData[key].then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  } else {
    lastTimes[key] = Date.now()
    // eslint-disable-next-line no-return-assign
    return rememberPromiseData[key] = request.get(path, {
      params: data,
      ...options
    })
  }
}

export const apiMirror = {
  setApi(key, value) {
    apiMirror[key] = value
  }
}
