const fetch = (request, options) => {
  return new Promise((resolve, reject) => {
    const { url, method, params, config } = options
    const pKey = method === 'get' ? 'params' : 'data'
    const data = {}
    if (method === 'get') {
      data[pKey] = { params };
    } else {
      data[pKey] = params
    }
    request[method](`${url}`, data[pKey], config).then(data => {
      resolve(data)
    }).catch((err) => {
    //   const error = err.error ? JSON.stringify(err.error) : ''
      reject(err)
    }).finally(() => { resolve({}) })
  })
}

export default fetch
