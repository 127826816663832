import { DefaultDevice } from '~/settings/deviceSetting'
export const state = () => ({
  device: DefaultDevice,
  deviceWidth: 0
})

export const mutations = {
  SET_DEVICE(state, d) {
    state.device = d
  },

  SET_DEVICE_WIDTH(state, d) {
    state.deviceWidth = d
  }
}
export const actions ={
}
