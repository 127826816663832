import { Message } from 'element-ui'
import cloneDeep from 'lodash.clonedeep';
import {sendMessage} from "~/utils/event";
import {checkInIframe} from "~/utils/dom";
import {getNeedParamsFormRouteParams} from '~/utils'
import {SiteEnum} from "~/enums/siteEnum";
import EventBus from "~/utils/eventBus";
import {PageEnum, NativePaths} from "~/enums/pageEnum";

function SitePage () {
  this.list = []
  this.info = {}
  this.current = ''
  this.isProd = false
  this.$bus = new EventBus()
}

SitePage.prototype.append = function (pageItem) {
  const current = this.list.find(item => item.id === pageItem.id)
  if (!current) {
    this.list.push(pageItem)
  }
}

SitePage.prototype.setCurrent = function (data) {
  this.current = data
}

SitePage.prototype.get = function (link) {
  const current = this.list.find(item => item.link === link)
  if (current) {
    return cloneDeep(current)
  } else {
    Message.error('页面信息未找到，或链接已被修改！')
    throw new Error('Page Not Found')
  }
}

SitePage.prototype.setInfo = function (data) {
  this.info = data
}

SitePage.prototype.updateItem = function (pageItem) {
  const index = this.list.findIndex(item => item.id === pageItem.id)
  if (index > -1) {
    const newItem = Object.assign(cloneDeep(this.list[index]), pageItem)
    this.list.splice(index, 1, newItem)
  }
}
SitePage.prototype.toHome =  function () {
  this.to('主页')
}

SitePage.prototype.toShopCart = async function () {
  const $route = window.$nuxt.$route
  const params = getNeedParamsFormRouteParams($route.params, sitePage.isProd)
  const $router = window.$nuxt.$router
  const isPreview = $route.name === 'preview-sid-lang-pageid'
  const lang = params.lang || params.pageid
  if (isPreview) {
    const url = `/preview/${params.sid}/shop-cart/${PageEnum.SHOP_CART.includes(lang) ? '' : lang}`
    await $router.push(url)
  } else if (sitePage.isProd) {
    const url = `/shop-cart/${lang}`
    await $router.push(url)
  }
}

SitePage.prototype.toCoupon = async function() {
  const $route = window.$nuxt.$route
  const params = getNeedParamsFormRouteParams($route.params, sitePage.isProd)
  const $router = window.$nuxt.$router
  const isPreview = $route.name === 'preview-sid-lang-pageid'
  const lang = params.lang || params.pageid
  if (isPreview) {
    const url = `/preview/${params.sid}${PageEnum.COUPON}/${PageEnum.COUPON.includes(lang) ? '' : lang}`
    await $router.push(url)
  } else if (sitePage.isProd) {
    const url = `${PageEnum.COUPON}/${lang}`
    await $router.push(url)
  }
}

SitePage.prototype.to = async function (to) {
  if (!to) return
  const editMode = window.__site_builder_edit_init
  const $router = window.$nuxt.$router
  const $route = window.$nuxt.$route
  const inIframe = checkInIframe()
  const current = sitePage.list.find(item => item.name === to) || {}
  const toPage = async () => {
    const params = getNeedParamsFormRouteParams($route.params, sitePage.isProd)
    const { pageid } = params
    let path = $route.fullPath
    NativePaths.forEach(item => { path = path.replace(item, '') })
    const isToHome = current.id === SiteEnum.BASE_PAGE
    const isHead = pageid === '/'
    if (isHead) {
      const lastFlag = path.lastIndexOf('/')
      const qIndex = path.indexOf('?')
      const mark = (lastFlag === path.length - 1) ? '' : '/'
      const pathArr = path.split('')
      pathArr.splice(qIndex, 0, `${mark}${current.link}`)
      path = isToHome ? path : (qIndex === -1 ? path + `${mark}${current.link}` : pathArr.join(''))
    } else {
      path = isToHome ? (path.includes(`/${pageid}`) ? path.replace(`/${pageid}`, '') : path.replace(pageid, ''))  : path.replace(pageid, current.link)
    }
    await $router.push(path)
  }
  const openUrl =() => {
    window.open(to, '_blank')
  }
  const changeSitePage = () => {
    sitePage.$bus.$emit('change-page', current.link)
  }

  const isUrl = to.includes('http')
  if (isUrl) {
    openUrl()
  } else if (inIframe && editMode) {
    await sendMessage({ type: 'changeRouteQuery', data: current.link })
    changeSitePage()
  } else if (!inIframe && editMode) {
    await $router.push({ query: { page: current.link } })
    changeSitePage()
  } else {
    await toPage()
  }
}

const sitePage = new SitePage()

export default sitePage
