const Api = {
  // Upload: '/api/v2/merchant/tool/image/upload',
  AssetsList: '/api/v2/merchant/site/builder/image/list',
  DeleteAssets: '/api/v2/merchant/site/builder/image/del',
  AddAsset: '/api/v2/merchant/site/builder/image/add',
  BatchAdd: '/api/v2/merchant/site/builder/image/add/batch'
}
export default (request) => {
  return {
    /**
     * 只上传 google cdn
     */
    // upload: (params) => request.post(Api.Upload, params, { headers: { "Content-Type": "multipart/form-data" } }),

    /**
     * 上传并写表
     */
    addAsset: (params) => request.post(Api.AddAsset, params, { headers: { "Content-Type": "multipart/form-data" } }),
    batchAddAssets: (params) => request.post(Api.BatchAdd, params, { headers: { "Content-Type": "multipart/form-data" } }),

    /**
     * 获取资源
     */
    getAssets: (params) => request.get(Api.AssetsList, {
      params: {
        ...params,
        local_tz: new Date().getTimezoneOffset()
      }
    }),

    /**
     * 删除资源
     */
    deleteAsset: (data) => request.delete(Api.DeleteAssets, { data }),
  }
}
