import {MessageEnum} from "~/enums/messageEnum";
export const state = () => ({
  error: {
    message: '',
    code: '',
    mode: ''
  }
});

export const actions = {
  //
}

export const mutations = {
  UPDATE_ERROR(state, payload){
    const type = typeof payload
    if(!payload) {
      state.error = {
        message: '',
        code: '',
        mode: ''
      }
    }
    if(type === 'string') {
      state.error = {
        message: payload,
        code: '',
        mode: MessageEnum.MESSAGE
      }
    }else if(type === 'object' && payload !== null){
      state.error = payload;
    }else{
      // todo
    }
  },
}
