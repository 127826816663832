export const APIKEY = '231532:9a9ffd40b338da89838e9144af6f04a6'
export const MERCHANT_ID = '231532'
// export const PROJECT_ID = 167486
export const PROJECT_ID = 186367
export const URL = 'https://secure.xsolla.com/paystation3/?access_token='
export const TEST_URL = 'https://sandbox-secure.xsolla.com/paystation3/?access_token='

export const isXsollaApi = (url) => {
  return url.includes('xsolla')
}

export const openPay = (token, isProd) => {
  window.open(`${isProd ? URL : TEST_URL}${token}`, '_blank')
}

export const checkXsollaResponse = (response) => {
  let valid = false
  const { config } = response
  if (isXsollaApi(config.url)) {
    valid = true
  }
  return valid
}
