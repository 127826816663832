export const state = () => ({
  menuKeys: []
})

export const mutations = {
  ADD_MENU_KEY(state, data, model,target) {
    !state.menuKeys.includes(data) && state.menuKeys.push(data)
  },
  REMOVE_MENU_KEY(state, data) {
    if (data) {
      state.menuKeys.splice(state.menuKeys.indexOf(data), 1)
    } else {
      state.menuKeys.splice(state.menuKeys.length - 1, 1)
    }
  },
  CLEAR_MENU_KEY(state, keys) {
    Array.isArray(keys) && keys.forEach(item => {
      state.menuKeys.splice(state.menuKeys.indexOf(item), 1)
    })
    if (!keys || keys.length === 0) {
      state.menuKeys = []
    }
  }
}
export const actions ={
}
