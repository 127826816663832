// import {PageEnum} from '~/enums/pageEnum'
import {TokenName} from '~/config/cookieKeys'
import { blackList } from '~/hooks/useRouteSetting'
import { isRelease, debugPayPath } from '~/config'

export default async function (context) {
  const { redirect, app, route,} = context // redirect,
  const currentPath = route.path;
  if (isRelease && currentPath === debugPayPath) {
    redirect('/401')
  }

  if (blackList.some( p => currentPath.indexOf(p) === 0)) {
    const cookiesToken = app.$cookies.get(TokenName)
    if (cookiesToken) {  // 有token代表已经登录过了
      let user = app.$cookies.get('userInfo')
      if (!user) {
        const res = await app.$api.siteBuild.getMerchantUserInfo()
        user = res.user
        await app.$utils.setAuthInfo(context, res)
      }

      if (!user) {
        app.$utils.loginOut(context)
      }
    } else {
      // fix: 解决别的网站重定向过来服务的无法获取cookie问题，服务端获取不到继续重定向到重定向地址，获取本地cookie
      const and = route.fullPath.includes('?') ? '&' : '?';
      // 如果已经重定向过一次，还是没有cookie, 直接忽略，后续逻辑会重定向到401
      if (!route.fullPath.includes('time')) {
        redirect(route.fullPath + and + 'time='+new Date().getTime())
      }
    }
  } else {
    //
  }

}
