import { DefaultDevice } from '~/settings/deviceSetting'
export const state = () => ({
  currentRichTextId: '',
  selectWidgetId: '',
  currentModel: {
    id: ''
  },
  subId: '',
  editMode: false,
  device: DefaultDevice,
  pcDeviceWidth: 0,
  steps: [],
  stepsStorage: []
})

const appendStep = (data, payload, state) => {
  const { keys, value, sortArr, type, widget, callback } = payload
  if (payload.type === 'update') {
    const step = {
      keys,
      value,
      type,
      callback
    }
    step.keys?.length && data.push(step)
  } else if (payload.type === 'sort') {
    const step = {
      sortArr,
      type
    }
    data.push(step)
  } else if (payload.type === 'reduce' || payload.type === 'add') {
    const step = {
      widget,
      type
    }
    data.push(step)
  }
  console.log('storage', state.stepsStorage)
  console.log('steps', state.steps)
}

export const mutations = {
  SET_WIDGET_TAG(state, data) {
    state.widgetTag = data
  },
  SET_SELECT_WIDGET_ID(state, id) {
    state.selectWidgetId = id
  },
  SET_CURRENT_RICH_TEXT_ID(state, id) {
    state.currentRichTextId = id
  },
  SET_EDIT_MODE (state, bool) {
    state.editMode = Boolean(bool)
  },
  SET_DEVICE(state, device) {
    state.device = device
  },

  SET_PC_DEVICE_WIDTH(state, w) {
    state.pcDeviceWidth = w
  },

  SET_DEVICE_WIDTH(state, d) {
    state.deviceWidth = d
  },

  SET_CURRENT_MODEL(state, model) {
    state.currentModel = model
  },
  INSERT_STORAGE_STEP(state, payload) {
    appendStep(state.stepsStorage, payload, state)
  },
  INSERT_STEP(state, payload) {
    appendStep(state.steps, payload, state)
  },
  CLEAR_STORAGE_STEP(state) {
    state.stepsStorage = []
    state.subId = ''
  },
  REMOVE_LAST_STORAGE_STEP(state) {
    state.stepsStorage.pop()
  },
  REMOVE_LAST_STEP(state) {
    state.steps.pop()
  },
  SET_SUB_ID(state, id) {
    state.subId = id
  }
}
export const actions = {
}
