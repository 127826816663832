import Vue from 'vue'
import Message from './Message.vue'

const MessageBox = Vue.extend(Message)

Message.install = function (options, type) {
  if (options === undefined || options === null) {
    options = {
      content: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      content: options
    }
    if (type !== undefined && options !== null) {
      options.type = type;
    }
  }

  const instance = new MessageBox({
    data: options
  }).$mount()
  const siteWrapper = document.querySelector('.site-wrapper');
  siteWrapper && siteWrapper.appendChild(instance.$el)
  // document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
}

export default Message
