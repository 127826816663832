const Api =  {
  CreateSite: '/merchant/site_builder/add',
  SiteInfo: '/api/v2/store/site/builder/info',
  UpdateSiteInfo: '/api/v2/merchant/site/builder/edit' // /merchant/site_builder/edit
}
export default (request) => {
  return {
    // 创建网站
    createSite: (params) => request.post(Api.CreateSite, params),
    // 创建网站
    siteInfo: (params) => request.get(Api.SiteInfo, {params}),
    // 更新
    UpdateSiteInfo: (params) => request.post(Api.UpdateSiteInfo, params),
  }
}
