import {DeviceEnum} from "~/enums/deviceEnum";

export const DeviceList =  [
  {label: '桌面端',value: DeviceEnum.DESKTOP},
  {label: '移动端', value: DeviceEnum.MOBILE}
]
const ConstDevicePrefix = 'site-device-'
export const DeviceClassMapping = {
  [DeviceEnum.MOBILE]: ConstDevicePrefix + '750',
  [DeviceEnum.DESKTOP]: ConstDevicePrefix + DeviceEnum.DESKTOP,
}

export const DefaultDevice = DeviceEnum.DESKTOP

export const DefaultMobileDeviceWidth = 750
