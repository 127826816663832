class EventBus {
  constructor() {
    this.$listeners = {}
  }

  $on(key, cb) {
    if (!this.$listeners[key] || this.$listeners[key].length <= 0) {
      this.$listeners[key] = [
        cb
      ]
    } else {
      this.$listeners[key].push(cb)
    }
  }

  $emit(key) {
    if (this.$listeners[key]) {
      this.$listeners[key].forEach(cb => cb(arguments[1]))
    }
  }

  $off(key, cb) {
    const cbs = this.$listeners[key]
    if (cbs) {
      if (cb) {
        const l = cbs.length
        for (let j = l - 1; j > 0; j--) {
          if (cb === cbs[j]) {
            cbs.splice(j, 1)
          }
        }
      } else if (key) {
        this.$listeners[key] = null
      } else {
        this.$listeners = {}
      }
    }
  }
}

export default EventBus
