/**
 * @description: Device Enum
 */
export const DeviceEnum = {
  DESKTOP : 'desktop',
  MOBILE: 'mobile'
}

export const DeviceWidthEnum = {
  MOBILE: 375
}
