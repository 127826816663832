export const PageEnum = {
  // 官网首页
  BASE_401: '/401',
  // 官网首页
  BASE_HOME: '/',
  // basic login path
  BASE_LOGIN: '/401',
  // 建站器预览页面
  SITE_PREVIEW: '/preview',
  // 建站器编辑页面
  SITE_EDIT: '/sites',
  // basic home path
  BASE_DASHBOARD: '/dashboard',
  DashboardPageEnum: '/dashboard',
  // basic login path
  PREVIEW_PAGE_ENUM: '/preview',
  // error page path
  ERROR_PAGE: '/exception',
  // error log page path
  ERROR_LOG_PAGE: '/error-log/list',
  // forbidden page
  FORBIDDEN_PAGE: '/forbidden',
  SHOP_CART: '/shop-cart',
  COUPON: '/coupon'
}


export const PageNameEnum = {
  SHOP_CART: 'shop-cart',
  COUPON: 'coupon'
}

export const NativePages = [PageNameEnum.SHOP_CART, PageNameEnum.COUPON]

export const NativePaths = [PageEnum.SHOP_CART, PageEnum.COUPON]
