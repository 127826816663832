import debounce from "lodash.debounce";
import { once } from "~/utils/dom"

export function postMessage(message) {
  window.parent.postMessage(message, '*')
}

export function sendMessage(detail) {
  return new Promise((resolve, reject) => {
    once(window, 'message', (event) => {
      const origin = event.origin || event.originalEvent.origin
      if (origin) {
        if (event.data?.type === detail.type) {
          resolve(event.data)
        }
      }
    })
    postMessage(detail)
  })
}

export const insertStep = debounce((context, keys, value, callback) => {
  const idFlag = 'site-widget'
  if (keys.filter(item => item).length === 0) return
  !keys[0].includes(idFlag) && keys.unshift(context.$store.state.editor.selectWidgetId)
  context.$store.commit('editor/INSERT_STEP', {
    keys: keys.filter(item => item !== null && item !== ''),
    value,
    callback,
    type: 'update'
  })
}, 400)

export const insertSortStep = debounce((context, sortArr) => {
  context.$store.commit('editor/INSERT_STEP', {
    sortArr,
    type: 'sort'
  })
}, 400)

export const insertReduceStep = debounce((context, widget) => {
  context.$store.commit('editor/INSERT_STEP', {
    widget,
    type: 'reduce'
  })
}, 400)

export const insertAddStep = (context, widget) => {
  context.$store.commit('editor/INSERT_STEP', {
    widget,
    type: 'add'
  })
}
