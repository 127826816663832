export const LOCALE = {
  ZH_CN: 'cn',
  EN_US: 'en',
};


// locale list
export const LocaleList = [
  {
    label: '简体中文',
    value: LOCALE.ZH_CN,
  },
  {
    label: 'English',
    value: LOCALE.EN_US,
  },
];

export const DefaultLocale = {
  EditMode: {
    locale: LOCALE.ZH_CN,
    fallbackLocale:LOCALE.EN_US
  },
  PreviewMode: {
    locale: LOCALE.EN_US,
    fallbackLocale:LOCALE.EN_US
  }
}
