export const state = () => ({
  recentColors: []
})

export const mutations = {
  SET_RECENT_COLORS(state, color) {
    const max = 14
    if (state.recentColors.length < max) {
      const has = state.recentColors.includes(color)
      !has && state.recentColors.push(color)
    }
  }
}
export const actions ={
}
